import React from 'react';
import {Table} from 'antd';
import {UPGRADE_REQ_TO} from '../../dictionary';
import Button from '../../Widgets/Button';
import Anchor from '../../Widgets/Anchor';

const TableTypes = {
  NORMAL: 0,
  ENTERPRISE: 1,
};

const STATUS = {
  pending: '待審核',
  rejected: '已拒絕',
  accepted: '已通過',
};

const RequestUpgradeTable = ({
  records,
  openModalInstance,
  loading,
  pagination,
  onPaging,
}) => {
  const columns = [
    {
      title: '會員編號',
      render: (_, instance) => (
        <Anchor
          to={`/member/?id=${instance.issuer}`}>{`#${instance.issuer}`}</Anchor>
      ),
      width: 150,
      fixed: 'left',
    },
    {
      title: '顧客(公司)名稱',
      render: (_, instance) => (
        <div>{`${
          JSON.parse(instance.data).name || JSON.parse(instance.data).ent_name
        }`}</div>
      ),
      width: 140,
    },
    {
      title: '公司統編',
      render: (_, instance) => (
        <div>{`${JSON.parse(instance.data).gui_number || '--'}`}</div>
      ),
      width: 140,
    },

    {
      title: '申請成為',
      render: (_, instance) => `${UPGRADE_REQ_TO[instance.req_to]}`,
      width: 100,
    },
    // {
    //   title: "審核狀態",
    //   render: (_, instance) => <div>{`${STATUS[instance.state]}`}</div>,
    //   width: 130
    // },
    {
      title: '拒絕原因',
      dataIndex: 'reason',
      width: 130,
    },
    {
      title: '審核',
      render: (_, instance) => (
        <Button
          disabled={instance.state !== 'pending'}
          onClick={() => openModalInstance(instance)}>{`${
          STATUS[instance.state]
        }`}</Button>
      ),
      width: 130,
    },
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={records}
      rowKey={(record) => record.id}
      pagination={pagination}
      onChange={onPaging}
    />
  );
};

export default RequestUpgradeTable;

export {TableTypes};
