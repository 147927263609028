import React, {useState, useCallback, Fragment, useEffect} from 'react';
import styled from 'styled-components';
import RequestUpgradeTable, {TableTypes} from './RequestUpgradeTable';
import ReviewUpgradeDialog from '../../Dialogs/ReviewUpgradeDialog';
import {navigate} from 'gatsby';
const appConfig = require('../../data.json');
import {useOutlet} from 'reconnect.js';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import Tabs from '../../Widgets/Tabs';

const FILTER_STATE = {
  monthly: '申請成月結會員',
  enterprise: '申請成企業會員',
};

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function RequestUpgradeList(props) {
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    req_to: 'monthly',
    ...PAGINATION_INIT,
  });
  const [selected, setSelected] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');

  function filterChange(obj) {
    setFilters((prev) => ({...prev, ...PAGINATION_INIT, ...obj}));
  }

  const getRecords = useCallback(async () => {
    setLoading(true);
    let params = {
      ...filters,
      ordering: '-created',
    };
    try {
      let {results, count} = await actions.getRequestUpgrades(params);
      setRecords(results);
      setTotal(count);
    } catch (err) {
      console.warn(err);
    }
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <div style={{flex: 1}} />
            <Button
              type="primary"
              onClick={getRecords}
              icon={
                <Refresh color="#fff" size={20} style={{marginRight: 5}} />
              }>
              刷新
            </Button>
          </div>
        </div>
      </Block>

      {/* <div className="filter-row">
                    <div className="label">搜尋</div>
                    <Input
                      value={filters.search_str}
                      onChange={e => {
                        let value = e.target.value;
                        this.setState(state => ({
                          filters: { ...state.filters, search_str: value }
                        }));
                      }}
                      placeholder="姓名、電話、Email"
                    />
                  </div> */}

      <Block>
        <div>
          <Tabs
            value={filters.req_to}
            onChange={(value) => filterChange({req_to: value})}
            options={Object.keys(FILTER_STATE)
              .filter((x) => x === 'monthly')
              .map((x) => ({label: FILTER_STATE[x], value: x}))}
            style={{marginBottom: 10}}
          />

          <RequestUpgradeTable
            type={TableTypes.NORMAL}
            loading={loading}
            records={records}
            mounted={true}
            openModalInstance={(record) => {
              setSelected(record);
              setOpenModal(true);
            }}
            pagination={{
              pageSize: filters.pageSize,
              current: filters.current,
              total,
            }}
            onPaging={(pagination) =>
              filterChange({
                pageSize: pagination.pageSize,
                current: pagination.current,
              })
            }
          />
        </div>
      </Block>

      <ReviewUpgradeDialog
        visible={openModal}
        onClose={() => {
          setOpenModal(false);
          setSelected(null);
        }}
        record={selected}
        onRefresh={getRecords}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
